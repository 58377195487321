<template>
<Intersect @enter.once="animation1=true" @leave.once="animation1=false" :threshold="[1.0]">
    <div class="height-div">
        <transition name="slide-fade">
            <div  class="flex-item-row offset pt-16 pb-16 " v-if="animation1"> 
                <p class="title-2">Adquiere tu Yipi</p>    
                <picture >
                    <source media="(max-width: 700px)" srcset="../assets/animacion/11/Yipi_AnimacionWeb_11_Ipad.gif">
                    <img loading="lazy" class="video-animation" src="../assets/animacion/11/Yipi_AnimacionWeb_11.gif" alt="animation dashboard">
                </picture>

            </div>
        </transition>
    </div>
</Intersect>
</template>
<script>
  import Intersect from 'vue-intersect'
export default {
    components:{
    Intersect
    },
    data: () => ({
        animation1:false,

    })
}
</script>